.numberCircle {
  /* border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 2px;

  background: #fff;
  border: 2px solid #666; */
  color: #666;
  /* text-align: center; */

  font: 12px Arial, sans-serif;
}

.bg-primary .numberCircle {
  color: white;
  border-color: white;
}

.text-white {
  color: "white";
  border-radius: "5px";
}

.edit {
color: "#777"
}

