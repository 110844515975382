.hidden {
  transition: opacity 600ms;
  opacity: 0.1;
}
.active {
  opacity: 1;
}

.customCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  width: 80px;
}

.customCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.customMenu {
  position: absolute;
  left: 50px;
  top: -10px;
}

.customMenuItem {
  cursor: pointer;
  padding: 6px 10px;
}
.customMenuItem:hover {
  cursor: pointer;
  background-color: #e4ecec;
}
