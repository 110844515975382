table{
  font-size: xx-small;
}

td {
  text-align: end!important;
  font-weight: 600;
}

.first{
  text-align: start !important;
}

.center{
  text-align: center !important;
}

.spaceBetween {
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}

.tableStyle {
  width: 100%;
}
td,
th {
  vertical-align: middle;
}

input[type="checkbox"] {
  /* 1.5-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
}

.chequeText {
  margin-top: 2%;
  height: 30%;
  width: 96%;
}

.cheque {
  display: flex;
  align-content: center;
  justify-content: center;
}

.chequeDate {
  margin-top: 50px;
  margin-right: 2%;
  display: flex;
  align-content: center;
  justify-content: flex-end;
}

.date {
  font-size: 12px;
  letter-spacing: 5px;
}

.dateFormat {
  letter-spacing: 5px;
  font-size: 9px;
}

.dateText {
  font-size: 12px;
  padding-right: 10px;
}

.pay {
  margin-top: 60px;
  color: black;
  font-weight: 600;
  display: flex;
  align-content: center;
}

.payText {
  font-size: 12px;
  margin-right: 2%;
}


.payNumber {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
}

.workerInfo {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  color: black;
  font-weight: 600;
  margin-top: 25px;
}

.signature {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  margin-right: 2%;
  margin-top: 45px;
  color: black;
  font-weight: 600;
}

#select-all {
  visibility: hidden;
}

.btn-outline-danger {
  border: 0 !important  ;
}